import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import './App.css';
import Tooltip from './Tooltip';
import DropdownMenu from './DropdownMenu';
import Trainer from './Trainer';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, storage } from './firebase-config';
import { useAuth } from './AuthContext';
import { db } from './firebase-config'; // Assumed import, adjust based on actual export
import { doc, getDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { setDoc } from 'firebase/firestore'; // For saving data to Firestore
import { getStorage, ref, listAll, uploadBytes, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage'; // For uploading images to Firebase Storage
import { Helmet } from 'react-helmet';
import Preferences from './Preferences';


const HistorySize = 30;

const DrawingBoard = forwardRef(({ width, height, brushSize, saveToHistory, useGenBrushTool}, ref) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [hasDrawn, setHasDrawn] = useState(false); 

  const getMousePos = (canvas, event) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    return {
      x: (event.clientX - rect.left) * scaleX,
      y: (event.clientY - rect.top) * scaleY
    };
  }

  const startDrawing = (event) => {
    if (event.nativeEvent.button !== 0) {
      return; // Do nothing if it's not a left-click
    }
  
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const mousePos = getMousePos(canvas, event.nativeEvent);
  
    const imageDataUrl = canvas.toDataURL();
    saveToHistory(imageDataUrl, 'draw');
  
    ctx.beginPath();
    ctx.moveTo(mousePos.x, mousePos.y);
    setIsDrawing(true);
  };

  const handleMouseDown = (event) => {
    if (event.nativeEvent.button === 2) { // Right-click detected
      const canvas = canvasRef.current;
      canvas.style.pointerEvents = 'none';

      // Restore pointer-events when the mouse button is released
      const restorePointerEvents = () => {
        canvas.style.pointerEvents = 'auto';
        document.removeEventListener('mouseup', restorePointerEvents);
      };

      document.addEventListener('mouseup', restorePointerEvents);

      // Allow the right-click event to pass through to the underlying element
      return;
    } else if (event.nativeEvent.button === 0) { // Left-click detected
      startDrawing(event);
    }
    // You can handle other mouse buttons if needed
  };

  const draw = (event) => {
    if (!isDrawing) {
      return;
    }
    setHasDrawn(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const mousePos = getMousePos(canvas, event.nativeEvent);

    ctx.lineTo(mousePos.x, mousePos.y);
    ctx.strokeStyle = 'rgba(230, 240, 255, 1)'; // White color
    ctx.lineWidth = brushSize; // Ensure brushSize is defined

    // To create a blur-like effect, you can use shadowBlur and shadowColor
    ctx.shadowBlur = 20; // Adjust blur level as needed
    ctx.shadowColor = 'rgba(230, 240, 255, 1)';

    ctx.lineCap = 'round'; // This creates a rounded brush
    ctx.lineJoin = 'round';
    ctx.stroke();
  };
  
  const stopDrawing = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.closePath();
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setHasDrawn(false);
  };

  const clearCanvasWithHistory = () => {
    const canvas = canvasRef.current;
    const imageDataUrl = canvas.toDataURL();
    clearCanvas();
    saveToHistory(imageDataUrl, 'draw');
  };

  useImperativeHandle(ref, () => ({
    getCanvasBase64: () => {
      const canvas = canvasRef.current;
      return canvas.toDataURL('image/png');
    },
    isCanvasEmpty: () => !hasDrawn,
    clearCanvas,
    clearCanvasWithHistory,
    undo: (dataUrl) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (dataUrl) {
        const img = new Image();
        img.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
        };
        img.src = dataUrl;
      }
    },
    redo: (dataUrl) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (dataUrl) {
        const img = new Image();
        img.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
        };
        img.src = dataUrl;
      }
    },
  }));

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Store the previous content of the canvas
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    canvas.style.position = 'absolute';
    canvas.style.top = '50%';
    canvas.style.left = '50%';
    canvas.style.transform = 'translate(-50%, -50%)';

    const handleResize = () => {
        if (canvasRef.current) {
            const container = canvasRef.current.parentElement;
            const containerAspectRatio = container.offsetWidth / container.offsetHeight;
            const imageAspectRatio = width / height;

            if (imageAspectRatio > containerAspectRatio) {
                canvasRef.current.style.width = '100%';
                canvasRef.current.style.height = 'auto';
                canvasRef.current.style.maxHeight = '';
            } else {
                canvasRef.current.style.height = '100%';
                canvasRef.current.style.width = 'auto';
                canvasRef.current.style.maxWidth = '';
            }
        }
    };

    // Update canvas width and height with new props
    canvas.width = width;
    canvas.height = height;

    // Restore the previous content of the canvas
    ctx.putImageData(imageData, 0, 0);

    window.addEventListener('resize', handleResize);
    handleResize();

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
}, [width, height]); // Only run the effect if width or height changes

  return (
    <canvas
    onMouseDown={handleMouseDown}
    onMouseMove={draw}
    onMouseUp={stopDrawing}
    onMouseOut={stopDrawing}
    ref={canvasRef}
    className="drawingBoard"
    style={{
        display: useGenBrushTool?.state ? 'block' : 'none',
      }}
    />
  );
});


function Tool() {

  const [confirmRemove, setConfirmRemove] = useState(false);
  const [trainerOpen, setTrainerOpen] = useState(false);
  const closeTrainer = () => setTrainerOpen(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const openPreferences = () => setShowPreferences(true);
  const closePreferences = () => setShowPreferences(false);

  const navigate = useNavigate();
  const [threadID, setThreadID] = useState('');
  const [runID, setRunID] = useState('');
  const [textInput, setTextInput] = useState('');
  const [assistantResponse, setAssistantResponse] = useState('');
  const [prompt, setPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [customerId, setCustomerId] = useState('');
  const { currentUser } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [activeSection, setActiveSection] = useState('settings');
  const [isChatVisible, setIsChatVisible] = useState(true);
  const [dragValue, setDragValue] = useState(1); // Default influence value
  const [dragging, setDragging] = useState(false);
  const [dragStartY, setDragStartY] = useState(0);
  const [dragIndex, setDragIndex] = useState(null);
  const [promptInfluences, setPromptInfluences] = useState({}); // Object to store influences by index
  const [fadeStates, setFadeStates] = useState([]); // Array to track fade-in state for each prompt
  const [showDragValue, setShowDragValue] = useState(false);
  const [gettingLabels, setGettingLabels] = useState(false);
  const [isEditingPrompt, setIsEditingPrompt] = useState(false);  // State to track if editing

  const parsePrompt = (prompt) => {
    // RegEx to correctly parse out terms and their influences
    const parsedList = prompt.split(',').map(term => {
      const match = term.trim().match(/^\((.*?):([\d.]+)\)$/);
      if (match) {
        return { term: match[1].trim(), influence: parseFloat(match[2]) };
      }
      return { term: term.trim(), influence: 1 };
    });
    return parsedList.filter(item => item.term !== '');
  };
  
  useEffect(() => {
    if (!prompt) return;
    const initialParsedList = parsePrompt(prompt);
    const newInfluences = {};
    initialParsedList.forEach(item => {
      newInfluences[item.term] = item.influence;
    });
    setPromptInfluences(newInfluences);
    initializeFadeStates(initialParsedList.length); // Initialize fade states
  }, [prompt]);

  const initializeFadeStates = (length) => {
    // Set states to false initially and then update them to true to trigger fade-in
    setFadeStates(new Array(length).fill(false));
    setTimeout(() => {
      setFadeStates(new Array(length).fill(true));
    }, 0);
  };

  const promptList = parsePrompt(prompt);

  const [editableIndex, setEditableIndex] = useState(null);
  const [editablePrompt, setEditablePrompt] = useState("");

  const handleDoubleClick = (index, term) => {
    setEditableIndex(index);
    setEditablePrompt(term);
    setIsEditingPrompt(true);
  };

  const handlePromptChange = (e) => {
    setEditablePrompt(e.target.value);
  };

  const handlePromptBlur = () => {
    // Remove prompt if the new term is empty or whitespace
    if (editablePrompt.trim() === '') {
      const updatedPromptList = promptList.filter((_, idx) => idx !== editableIndex);
      const updatedPrompt = updatedPromptList.map(item =>
        `(${item.term}:${item.influence})`
      ).join(', ');
  
      setPrompt(updatedPrompt); // Update the prompt state
      setEditableIndex(null); // Exit the edit state
      setIsEditingPrompt(false); // Ensure isEditing is set to false
      return;
    }
  
    const updatedPromptList = [...promptList];
    const oldTerm = promptList[editableIndex].term;
    const influence = promptInfluences[oldTerm] || 1;
  
    // Update the term in the list and the influences
    updatedPromptList[editableIndex] = { term: editablePrompt.trim(), influence };
    const newInfluences = { ...promptInfluences };
    delete newInfluences[oldTerm];
    newInfluences[editablePrompt.trim()] = influence;
  
    // Synchronize updated prompt list and influences
    const updatedPrompt = updatedPromptList.map(item =>
      `(${item.term}:${newInfluences[item.term]})`
    ).join(', ');
  
    setPrompt(updatedPrompt); // Update the prompt state
    setPromptInfluences(newInfluences);
    setEditableIndex(null); // Exit the edit state
    setIsEditingPrompt(false); // Ensure isEditing is set to false
  };

  const addPrompt = () => {
    const currentPromptList = parsePrompt(prompt);
    const newPromptList = [{ term: "", influence: 1 }, ...currentPromptList];
    const updatedPrompt = newPromptList.map(item => 
      `(${item.term}:${item.influence})`
    ).join(', ');
  
    setPrompt(updatedPrompt);
    setEditableIndex(0);  // Since the new prompt is at the beginning
    setEditablePrompt("");
    
    initializeFadeStates(newPromptList.length);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handlePromptBlur();
    }
  }


  const handleMouseDown = (index, e) => {
    if (!isEditingPrompt) {
      setDragStartY(e.clientY);
      setDragging(true);
      setDragIndex(index);
      setDragValue(promptInfluences[promptList[index].term] ?? 1); // Initialize dragValue to current influence
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging || dragIndex === null) return;
    const dragDistance = dragStartY - e.clientY;
    const initialInfluence = promptInfluences[promptList[dragIndex].term] ?? 1;
    const newValue = initialInfluence + dragDistance / 100; // Adjust the divisor for sensitivity
    const clampedValue = Math.min(1.5, Math.max(0, newValue));
    const roundedValue = parseFloat(clampedValue.toFixed(2));
  
    setDragValue(roundedValue);
    setShowDragValue(true);
  
    const updatedInfluences = { ...promptInfluences, [promptList[dragIndex].term]: clampedValue };
    setPromptInfluences(updatedInfluences);
  
    const updatedPrompt = promptList.map(item =>
      `(${item.term}:${updatedInfluences[item.term].toFixed(2)})`
    ).join(', ');
    setPrompt(updatedPrompt);
  };

  const handleMouseUp = () => {
    if (dragging && dragIndex !== null) {
      setDragging(false);
      setDragIndex(null);
      setShowDragValue(false); // Hide drag value when dragging stops
    }
  };

  useEffect(() => {
    // Add and remove mousemove and mouseup listeners based on dragging state
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);

  useEffect(() => {
    if (promptList.length > fadeStates.length) {
      initializeFadeStates(promptList.length);
    }
  }, [promptList.length, fadeStates.length]);

  const interpolateColor = (value, colorStops) => {
    if (!colorStops || colorStops.length < 3) {
      console.error('Invalid color stops:', colorStops);
      return '#000000'; // Fallback color
    }

    value = Math.min(1.5, Math.max(0, value)); // Clamp value between 0 and 2

    if (value <= 1) {
      // Interpolate between minColor and midColor
      const ratio = value;
      const [r1, g1, b1] = colorStops[0];
      const [r2, g2, b2] = colorStops[1];
      const r = Math.round(r1 + (r2 - r1) * ratio);
      const g = Math.round(g1 + (g2 - g1) * ratio);
      const b = Math.round(b1 + (b2 - b1) * ratio);
      return `rgb(${r}, ${g}, ${b})`;
    } else {
      // Interpolate between midColor and maxColor
      const ratio = value - 1;
      const [r1, g1, b1] = colorStops[1];
      const [r2, g2, b2] = colorStops[2];
      const r = Math.round(r1 + (r2 - r1) * ratio);
      const g = Math.round(g1 + (g2 - g1) * ratio);
      const b = Math.round(b1 + (b2 - b1) * ratio);
      return `rgb(${r}, ${g}, ${b})`;
    }
  };

  const minBgColor = [34, 37, 37];  // RGB for #222525
  const midBgColor = [51, 54, 54];  // RGB for #333636
  const maxBgColor = [89, 176, 144]; // RGB for #59b090
  const bgColorStops = [minBgColor, midBgColor, maxBgColor]; 

  const minTextColor = [128, 128, 128]; // RGB for #808080
  const midTextColor = [150, 150, 150]; // RGB for #c0c0c0
  const maxTextColor = [305, 305, 305]; // RGB for white
  const textColorStops = [minTextColor, midTextColor, maxTextColor];

  const handleChatButtonClick = () => {
    setIsChatVisible(!isChatVisible);
  };

  const openTrainer = () => {

    if (loras.length >= 3) {
      alert("Currently the maximum lenses limit is 3. We will keep increasing this number as we test the system further. Please delete an existing Lens to start a new one.");
      return;
  
    }
  
    setTrainerOpen(true);
  
  };


  
  const toggleHoverState = () => {
    setIsHovered(!isHovered);
  };

  const handleTitleClick = (section) => {
    setActiveSection(section);

    if (section === 'training') {
      loadUserLoras();
    }
  };

  const fetchUserDetails = async (userId) => {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);
  
    if (userSnap.exists()) {
      return userSnap.data();
    } else {
      // Handle case where user data does not exist
      console.log("No such document!");
      return {};
    }
  };

  useEffect(() => {
    // Ensure currentUser exists before fetching details
    if (currentUser) {
      fetchUserDetails(currentUser.uid)
        .then(userDetails => {
          if (userDetails.stripeCustomerId) {
            setCustomerId(userDetails.stripeCustomerId);
          }
        })
        .catch(error => {
          console.error("Failed to fetch user details", error);
        });
    }
  }, [currentUser]);

  // Function to fetch user details
  const manageSubscription = async (customerId) => {
    try {
      const response = await fetch('/.netlify/functions/create-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId }), // Previously obtained customer ID
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const { url } = await response.json();
  
      // Redirect the user to the Customer Portal
      window.location.href = url;
    } catch (error) {
      console.error('Error redirecting to customer portal:', error);
    }
  };

  useEffect(() => {
    const wakeUpGPU = async () => {
      try {
        const response = await fetch('/.netlify/functions/wakeup-ping', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error while trying to send ping', error);
      }
    };

    wakeUpGPU();
  }, []); // The empty array ensures this effect only runs once on mount



  // TEMP: 'masterpiece, best quality, car design, reflection paint, simple white background, unreal engine,'
  
  const [imageData, setImageData] = useState('');
  const [inspirationData, setInspirationData] = useState('');
  const [history, setHistory] = useState([]);
  const [outputDimensions, setOutputDimensions] = useState({ width: 864, height: 512 });
  const [denoisingStrength, setDenoisingStrength] = useState(0.65);
  const [brushSize, setBrushSize] = useState(20);
  const [controlnetStrength, setControlnetStrength] = useState(0.75);
  const [inspirationStrength, setInspirationStrength] = useState(0.5);
  const [shouldResize, setShouldResize] = useState(true);
  const [jobProgress, setJobProgress] = useState('Finished!');
  const [menuOpen, setMenuOpen] = useState(false);
  const [position, setPosition] = useState(null);
  const [presets, setPresets] = useState([
    { id: 1, name: 'Preset 1' },
  ]);
  const [loras, setLoras] = useState([]);
  const [loadingLoras, setLoadingLoras] = useState(false);
  const [loraInfluenceValues, setLoraInfluenceValues] = useState({});
  const [confirmLoraDeleteState, setConfirmLoraDeleteState] = useState({}); // Add a state to track confirmation
  const [contextAwareGeneration, setContextAwareGeneration] = useState(true);
  const [modelVersion, setModelVersion] = useState('0.6.0');
  const [deletingLora, setDeletingLora] = useState(false);


  const loadUserLoras = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingLoras(true);

    const storage = getStorage();

    try {
      const lorasColRef = collection(db, 'userSettings', currentUser.uid, 'loras');
      const querySnapshot = await getDocs(lorasColRef);

      const loadedLoras = await Promise.all(
        querySnapshot.docs.map(async doc => {
          const data = doc.data();
          if (data.status === 'completed') {
            const datasetPath = `datasets/${currentUser.uid}/${doc.id}/`;
            const folderRef = ref(storage, datasetPath);
            const listResponse = await listAll(folderRef);

            let thumbnailUrl = '';

            if (listResponse.items.length > 0) {
              for (const itemRef of listResponse.items) {
                const itemMetadata = await getMetadata(itemRef);

                if (itemMetadata.contentType.startsWith('image/')) {
                  thumbnailUrl = await getDownloadURL(itemRef);
                  break; // Exit loop once the first image is found.
                }
              }
            }

            return {
              id: doc.id,
              name: data.project_name,
              training_mode: data.training_mode,
              status: data.status,
              thumbnailUrl
            };
          } else if (data.status === 'failed') {
            alert('Something went wrong with the Lens training, please try again. If this persists, please let us know on our Discord channel so we can fix it for you ASAP.');
  
            // Delete the Firestore document
            await deleteDoc(doc(db, 'userSettings', currentUser.uid, 'loras', doc.id));
  
            // Delete files from Storage
            const loraFolderRef = ref(storage, `loras/${currentUser.uid}/${doc.id}`);
            const loraListResponse = await listAll(loraFolderRef);
  
            // Delete each file in the 'loras' folder
            const loraDeletePromises = loraListResponse.items.map(itemRef => deleteObject(itemRef));
            await Promise.all(loraDeletePromises);
  
            const datasetsFolderRef = ref(storage, `datasets/${currentUser.uid}/${doc.id}`);
            const datasetsListResponse = await listAll(datasetsFolderRef);
  
            // Delete each file in the 'datasets' folder
            const datasetsDeletePromises = datasetsListResponse.items.map(itemRef => deleteObject(itemRef));
            await Promise.all(datasetsDeletePromises);
  
            setConfirmLoraDeleteState(prevState => {
              const newState = { ...prevState };
              delete newState[doc.id];
              return newState;
            });
  
            return null;
          }
          return null;
        })
      );

      const filteredLoras = loadedLoras.filter(lora => lora !== null);
       setLoras(filteredLoras);

    } catch (error) {
      console.error("Error loading loras: ", error);
    } finally {

      setLoadingLoras(false);
  
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    loadUserLoras();
  }, [loadUserLoras]);

  const deleteLora = async (loraId, datasetId) => {

    setDeletingLora(true);
    try {
      // Delete from Firestore
      await deleteDoc(doc(db, 'userSettings', currentUser.uid, 'loras', loraId));

      // Delete files from Storage
      const storage = getStorage();
      const folderRef = ref(storage, `loras/${currentUser.uid}/${datasetId}`);
      const listResponse = await listAll(folderRef);
      
      // Delete each file in the folder
      const deletePromises = listResponse.items.map(itemRef => deleteObject(itemRef));
      await Promise.all(deletePromises);

      const datasetsFolderRef = ref(storage, `datasets/${currentUser.uid}/${datasetId}`);
      const datasetsListResponse = await listAll(datasetsFolderRef);
      
      // Delete each file in the 'datasets' folder
      const datasetsDeletePromises = datasetsListResponse.items.map(itemRef => deleteObject(itemRef));
      await Promise.all(datasetsDeletePromises);
      
      // Remove from state
      setLoras(prevLoras => prevLoras.filter(lora => lora.id !== loraId));
      loadUserLoras();
      setConfirmLoraDeleteState(prevState => {
        const newState = { ...prevState };
        delete newState[loraId];
        return newState;
      });
    } catch (error) {
      console.error("Error deleting Lora: ", error);
    } finally {
      setDeletingLora(false);
    }
  };

  const handleDeleteClick = (loraId, datasetId) => {
    // Check if already in confirmation state
    if (confirmLoraDeleteState[loraId]) {
      // Proceed with deletion
      deleteLora(loraId, datasetId);
    } else {
      // Set the confirmation state for this loraId
      setConfirmLoraDeleteState(prevState => ({
        ...prevState,
        [loraId]: true
      }));
    }
  };

  const handleMouseLeave = (loraId) => {
    // Reset the confirmation state for this loraId on mouse leave
    setConfirmLoraDeleteState(prevState => ({
      ...prevState,
      [loraId]: false
    }));
  };

  const handleInfluenceChange = (loraId, value) => {
    setLoraInfluenceValues(prevValues => ({
      ...prevValues,
      [loraId]: value
    }));
  };

  const [selectedPreset, setSelectedPreset] = useState(null);
  
  const handlePresetSelect = async (selectedPresetId) => {

    if (selectedPresetId === null || typeof selectedPresetId === 'undefined') {
      setSelectedPreset(null);
      console.log('No preset selected.');
      return; // Early return to prevent further execution
    }
    try {
      setSelectedPreset(selectedPresetId);
      await loadPreset(currentUser.uid, selectedPresetId.id);
    } catch (error) {
      console.error("Error selecting preset:", error);
    }
  };

  const handlePresetNameChange = (newName) => {
    const updatedPresets = presets.map(item =>
      item.id === selectedPreset.id ? { ...item, name: newName } : item
    );
    setPresets(updatedPresets);
    setSelectedPreset({ ...selectedPreset, name: newName });
  };

  const addPreset = () => {
    if (presets.length >= 5) {
      alert("You can currently have a maximum of 5 presets."); // Provide user feedback
      return; // Stop the function from proceeding
    }
  
    // Find the first available (non-existing) ID
    const newId = Math.max(0, ...presets.map(p => p.id)) + 1;

    const newPreset = { id: newId, name: `Preset ${newId}` };
  
    setPresets(prevPresets => [...prevPresets, newPreset]);
    setSelectedPreset(newPreset);
    savePreset(currentUser.uid, newPreset.id, newPreset.name, inspirationData);
  };

  const deletePreset = async (presetId) => {

    const userId = currentUser.uid; // Assuming you have `currentUser` with `uid`
  
    // Step 1: Delete from Firestore
    const docRef = doc(db, 'userSettings', String(userId), 'presets', String(presetId));

    try {
      // First, check if the document exists
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // Delete the image from Firebase Storage
        const presetData = docSnap.data();
        if (presetData.imageUrl) {
          // Assuming you store the full URL or direct path in `imageUrl`
          const imagePath = presetData.imageUrl; // Adapt this to extract the path if you're storing the full URL
          const imageRef = ref(storage, imagePath); // Adjust if you need to extract the path from the URL
          await deleteObject(imageRef); // Delete the image from Firebase Storage
        }

        // Proceed to delete the preset document
        await deleteDoc(docRef);
      } else {
        console.log('No preset to delete');
      }
    } catch (error) {
      console.error('Error removing document:', error);
      alert('Failed to delete preset');
    }
  
    const index = presets.findIndex(preset => preset.id === presetId);
    // Step 2: Delete locally
    const updatedPresets = presets.filter(preset => preset.id !== presetId); 
    setPresets(updatedPresets);
    
    // Determine the new selected preset based on the removed item's index
    if (updatedPresets.length > 0) {
      if (index >= updatedPresets.length) {
        // If the deleted preset was the last in the list, select the new last item
        setSelectedPreset(updatedPresets[updatedPresets.length - 1]);
      } else {
        // Otherwise, select the next item in the list (or the previous if it was the first item)
        setSelectedPreset(updatedPresets[index]);
      }
    } else {
      // If no presets remain, ensure no preset is selected
      setSelectedPreset(null);
    }    
  };

  const loadUserPresets = useCallback(async () => {
    if (!currentUser?.uid) return;

    try {
      const presetsColRef = collection(db, 'userSettings', currentUser.uid, 'presets');
      // Removed the where query because it seems redundant; you already specifically target the user's presets collection.
      const querySnapshot = await getDocs(presetsColRef);

      const loadedPresets = [];
      querySnapshot.forEach((doc) => {
        // Assuming 'presetName' is the field in your document that holds the name of the preset
        const data = doc.data(); // This will get all data fields from the document
        loadedPresets.push({
          id: doc.id,
          name: data.presetName // Here, you specifically get 'presetName' from the document's data
        });
      });

      setPresets(loadedPresets);
    } catch (error) {
      console.error("Error loading presets: ", error);
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    loadUserPresets();
  }, [loadUserPresets]);

  const toggleDefData = [
    { id: '1', name: 'Smart', res: 1536},
    { id: '2', name: 'Literal', res: 1536},
  ];
  const [activeDefButton, setActiveDefButton] = useState(toggleDefData[0]);


  const toggleDefActive = (id) => {
    const newActiveButton = toggleDefData.find(button => button.id === id);
    setActiveDefButton(newActiveButton);
  };
  const isDefActive = (button) => button.id === activeDefButton.id;

  const modelSize = [
    { id: '1', name: 'Normal', size: 1.4 },
    { id: '2', name: '2X', size: 1.75 },
  ];

  // const modelSize = [
  //   { id: '1', name: 'Normal', size: 1.45 },
  //   { id: '2', name: '2X', size: 1.7 },
  // ];
  const [activeSizeButton, setActiveSizeButton] = useState(modelSize[0]);


  const toggleSizeActive = (id) => {
    const newActiveButton = modelSize.find(button => button.id === id);
    setActiveSizeButton(newActiveButton);
  };
  const isSizeActive = (button) => button.id === activeSizeButton.id;

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    setMenuOpen(!menuOpen);
  };

    
  const isGenBrushToolActive = (button) => button.id === useGenBrushTool.id;

  const genBrushTool = [
    { id: '1', name: 'On', state: true },
    { id: '2', name: 'Off', state: false },
  ];
  
  const [useGenBrushTool, setUseGenBrushTool] = useState(genBrushTool[1]);

  const handleGenBrushClick = (button) => {
    setUseGenBrushTool(button); // Update the state
    if (!button.state) { // Check if the button state is "Off"
      handleClearCanvasButton(); // Trigger the clear canvas function
    }
  };

  const drawingBoardRef = useRef();

  const base64ToBlob = (base64) => {
    const [header, base64Data] = base64.split(',');
    if (!header || !base64Data) {
        throw new Error('Invalid base64 string');
    }

    const mimeType = header.match(/:(.*?);/)[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
};

const getImageFormat = (base64) => {
    const matches = base64.match(/^data:image\/([a-zA-Z]*);base64,/);
    return matches && matches.length > 1 ? matches[1] : null;
};

const savePreset = async (userId, presetSlot, presetName, inspirationData) => {
  try {
    if (presetSlot < 1 || presetSlot > 5) {
      throw new Error("Invalid preset slot. Allowed range is 1-5.");
    }

    let imageUrl = ''; // Default to empty string if no inspiration data

    // If inspirationData was provided, process and upload it
    if (inspirationData) {
      console.log("Processing inspiration data");
      const blob = base64ToBlob(inspirationData);
      const imageFormat = getImageFormat(inspirationData);

      if (!imageFormat) {
        throw new Error("Invalid image format");
      }

      const imageName = `inspiration-${userId}-slot-${presetSlot}-${Date.now()}.${imageFormat}`;
      const imageRef = ref(storage, `images/${userId}/${imageName}`);
      
      await uploadBytes(imageRef, blob);
      imageUrl = await getDownloadURL(imageRef);
    }

    // Compile all settings, including the now possibly updated imageUrl
    const settings = {
      imageUrl, // This may be an empty string or the URL from upload
      denoisingStrength, controlnetStrength, inspirationStrength,
      activeDefButtonId: activeDefButton?.id,
      activeSizeButtonId: activeSizeButton?.id,
      presetName,
      loras: loras.map(lora => ({
        id: lora.id,
        name: lora.name,
        influenceValue: loraInfluenceValues[lora.id] || 0.0,
        thumbnailUrl: lora.thumbnailUrl
      }))
    };

    // Update Firestore with the new preset settings
    const presetsRef = doc(db, 'userSettings', `${userId}`, 'presets', `${presetSlot}`);
    await setDoc(presetsRef, settings);
  } catch (error) {
    console.error("Error saving preset:", error.message);
  }
};
  
const loadPreset = async (userId, presetSlot) => {
  try {
    const presetRef = doc(db, 'userSettings', userId, 'presets', `${presetSlot}`);
    const docSnap = await getDoc(presetRef);

    if (docSnap.exists()) {
      const settings = docSnap.data();

      // Update component state
      setDenoisingStrength(settings.denoisingStrength);
      setControlnetStrength(settings.controlnetStrength);
      setInspirationStrength(settings.inspirationStrength);
      toggleDefActive(settings.activeDefButtonId);
      toggleSizeActive(settings.activeSizeButtonId);

      if (settings.imageUrl) {
        fetch(settings.imageUrl)
          .then(res => res.blob()) // Convert the response to a blob
          .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              // reader.result contains the Base64 string
              const base64String = reader.result;
              setInspirationData(base64String);
            };
            reader.readAsDataURL(blob); // Convert the blob to Base64
          })
          .catch(error => console.error("Error converting image to Base64: ", error));
      } else {
        // Explicitly set inspirationData to an empty string if imageUrl does not exist or is empty
        setInspirationData('');
      }

      if (settings.loras && Array.isArray(settings.loras)) {
        // Update lora influence values
        const updatedInfluenceValues = { ...loraInfluenceValues };
        settings.loras.forEach(presetLora => {
          const existingLora = loras.find(lora => lora.id === presetLora.id);
          if (existingLora) {
            updatedInfluenceValues[existingLora.id] = presetLora.influenceValue;
          }
        });
        setLoraInfluenceValues(updatedInfluenceValues);
      }
    } else {
      console.log("No preset found!");
      // Consider also resetting the inspirationData here if no preset exists
      setInspirationData('');
    }
  } catch (error) {
    console.error("Error loading preset: ", error);
    // Consider setting the inspirationData to '' in case of an error as well
    setInspirationData('');
  }
};




  const handleClearCanvas = () => {
    // Check if the clearCanvas function is available on the current ref object
    if (drawingBoardRef.current && drawingBoardRef.current.clearCanvas) {
      drawingBoardRef.current.clearCanvas();
    }
  };

  const handleClearCanvasButton = () => {
    // Check if the clearCanvas function is available on the current ref object
    if (drawingBoardRef.current && drawingBoardRef.current.clearCanvasWithHistory) {
      drawingBoardRef.current.clearCanvasWithHistory();
    }
  };


  const saveToHistory = (data, actionType) => {
    setHistory(prevHistory => {
      const lastEntry = prevHistory[prevHistory.length - 1];
  
      // Define the length of the base64 string portion to compare for non-draw actions
      const comparisonLength = 50; // Example length
  
      // Perform comparison only for non-draw actions
      if (lastEntry && actionType !== 'draw' && lastEntry.actionType === actionType) {
        // Extract portions of the base64 strings for comparison
        const newDataPortion = typeof data === 'string' ? data.substring(0, comparisonLength) : "";
        const lastDataPortion = typeof lastEntry.data === 'string' ? lastEntry.data.substring(0, comparisonLength) : "";
  
        if (newDataPortion === lastDataPortion) {
          // Skip adding a new entry as it's considered a duplicate
          return prevHistory;
        }
      }
  
      const newEntry = { actionType, data };
  
      // Append the new entry, ensuring the history doesn't exceed specified limits.
      const newHistory = [...prevHistory, newEntry].slice(-HistorySize);
      
      // Update the position to the end of the new history to reflect the latest action.
      setPosition(newHistory.length - 1)
      
      return newHistory;
    });
  };

  const setImageDataWithHistory = (newImageData) => {
    // Save current state to history before setting new image data

    setImageData(newImageData);
    saveToHistory(newImageData, 'imageChange');
  };

  const handleUndoButton = useCallback(() => {
    setHistory(currentHistory => {
      if (currentHistory.length > 1 && position != null && position > 0) {
        let newPos = position - 1;
  
        const last = currentHistory[newPos];
        
        if (last.actionType === 'draw') {
          // Make sure the drawingBoardRef.current?.undo reliably reverts one step
          drawingBoardRef.current?.undo(last.data);
        } else if (last.actionType === 'imageChange') {
          setImageData(last.data);
        }
        
        setPosition(newPos);
      } else {
        console.log("No previous steps to undo or you're already at the initial state.");
      }
      return currentHistory; // History itself is not modified here
    });
  }, [position, setPosition, setImageData, drawingBoardRef]);

  const handleRedoButton = useCallback(() => {
    setHistory(currentHistory => {
      if (currentHistory.length > 1 && position != null && position < currentHistory.length - 1) {
        // Directly increment position to redo the last undone action, navigating linearly through history.
        let newPos = position + 1;
        
        const next = currentHistory[newPos];
        
        if (next.actionType === 'draw') {
          // Assuming you have some mechanism to redo the draw action,
          drawingBoardRef.current?.redo(next.data);
        } else if (next.actionType === 'imageChange') {
          setImageData(next.data);
        }
        
        setPosition(newPos);
      } else {
        console.log("No next steps to redo.");
      }
      return currentHistory; // History itself is not modified here
    });
  }, [position, setPosition, setImageData]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Undo with Ctrl+Z or Cmd+Z
      if ((event.ctrlKey || event.metaKey) && event.key === 'z' && !event.shiftKey) {
        event.preventDefault();
        handleUndoButton();
      }
      
      // Redo with Ctrl+Shift+Z or Cmd+Shift+Z
      if ((event.ctrlKey || event.metaKey) && event.key === 'y' && !event.shiftKey) {
        event.preventDefault();
        handleRedoButton();
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndoButton, handleRedoButton]);   // Adding handleRedoButton to the dependency array

  
  const downloadImage = () => {
  // Create a new Image object
  const img = new Image();
  img.crossOrigin = 'anonymous'; // Ensure cross-origin access is allowed
  img.onload = () => {
    // Once the image is loaded, draw it onto a canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    
    // Convert the canvas content to a PNG URL
    const pngUrl = canvas.toDataURL('image/png');
    
    // Use an anchor element to trigger the download
    const element = document.createElement('a');
    element.href = pngUrl;
    element.download = 'download.png'; // Set the desired file name and extension
    document.body.appendChild(element);
    element.click(); // Trigger the download
    document.body.removeChild(element);
  };
  
  img.src = imageData; // Start loading the image
};


  const resetInput = (input) => {
    input.value = '';
};

  const [isDragActive, setIsDragActive] = React.useState(false);

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior
  };
  
  const handleDrop = (event) => {
    event.preventDefault(); // Prevent default behavior
    setIsDragActive(false); // Remove drag state
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      handleFileChange({ target: { files } });
    }
  };
  
  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragActive(false);
  };

  const handleRemoveImageClick = () => {
    if (confirmRemove) {
      setImageData('');
      setJobProgress('Finished!');
      setPrompt('');
      setNegativePrompt('');
      setConfirmRemove(false); 
    } else {
      setConfirmRemove(true); // Set state to ask for confirmation
    }
  };

  // Effect to reset confirmRemove after 3 seconds
  useEffect(() => {
    let timer;
    if (confirmRemove) {
      timer = setTimeout(() => {
        setConfirmRemove(false);
      }, 3000);
    }
    return () => clearTimeout(timer); // Cleanup timer on component unmount or update
  }, [confirmRemove]);



  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];
      const file = event.target.files[0];
      
      if (allowedTypes.includes(file.type)) {
        setShouldResize(true);
        const reader = new FileReader();
        
        reader.onloadend = async () => {
          const base64 = reader.result;

          let imageUrl = `data:${file.type};base64,${base64.split(',')[1]}`;



          if (shouldResize) {
            const img = new Image();
            img.onload = async () => {
              let width, height;
              const maxPixels = 500000; // Maximum number of pixels
  
              // Calculate new dimensions maintaining the aspect ratio
              const scalingFactor = Math.sqrt(maxPixels / (img.width * img.height));
              
              if (scalingFactor < 1) { // Only downscale if the image is larger than max pixels
                width = img.width * scalingFactor;
                height = img.height * scalingFactor;
              } else { // If the image is smaller than max allowed pixels, keep its original dimensions
                width = img.width;
                height = img.height;
              }
  
              // Make both dimensions divisible by 8
              width = Math.round(width / 8) * 8;
              height = Math.round(height / 8) * 8;
  
              setOutputDimensions({width, height});
              setImageDataWithHistory(base64);

              imageUrl = await resizeBase64Img(base64, width, height);
              
              if (contextAwareGeneration) {
                getImageLabels(imageUrl);
              }
            };
            img.src = base64;
          } else {
            if (contextAwareGeneration) {
              await getImageLabels(imageUrl);
            }
          }
          resetInput(event.target);
        };
        
        reader.readAsDataURL(file);
        handleClearCanvas();
        setHistory([]);
        setPosition(null);
        setThreadID('');
        setPrompt('');
        setNegativePrompt('');
        setAssistantResponse('New image, model reset to default.');
        setFadeStates([]);
      } else {
        alert('Please upload a JPG, WEBP or PNG file.');
        resetInput(event.target);
      }
    }
  };

  const getImageLabels = async (imageUrl) => {

    setGettingLabels(true);

    try {
      const authToken = await currentUser.getIdToken(); // 
      const response = await fetch('/.netlify/functions/handle-image-labeling', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}` 
        },
        body: JSON.stringify({ imageUrl })
      });
  
      const { prompt } = await response.json();
      setPrompt(prompt);
    } catch (error) {
      console.error('Error labeling image:', error);
    } finally {
      setGettingLabels(false);
    }
  }

  const inspirationInputRef = useRef(null);

  const handleInspirationClick = () => {
    if (inspirationInputRef.current) {
      inspirationInputRef.current.click();
    }
  };

  const handleRemoveInspiration = () => {
    setInspirationData('');
  };

  const handleInspirationChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];
      const file = event.target.files[0];
      
      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();
        
        reader.onloadend = () => {
          const base64 = reader.result;
          const img = new Image();
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            let width, height;
            
            if (img.width > img.height) {
              width = Math.min(1024, img.width);
              height = Math.round(width / aspectRatio);
            } else {
              height = Math.min(1024, img.height);
              width = Math.round(height * aspectRatio);
            }
            
            // Make both dimensions divisible by 8
            width = Math.round(width / 8) * 8;
            height = Math.round(height / 8) * 8;
            
            // Adjust the larger dimension if the smaller exceeds 2048 after rounding
            if (width > height && height > 1024) {
              height = 1024;
              width = Math.round(height * aspectRatio);
              width = Math.round(width / 8) * 8;
            } else if (height >= width && width > 1024) {
              width = 1024;
              height = Math.round(width / aspectRatio);
              height = Math.round(height / 8) * 8;
            }

            setInspirationData(base64);
          };
          img.src = base64;
        };
        
        reader.readAsDataURL(file);
  
      } else {
        alert('Please upload a JPG, WEBP or PNG file.');
      }
    }
  };

  function openLinkInNewTab(url) {
    window.open(url, '_blank');
  }

  function resizeBase64Img(base64, width, height) {
    return new Promise((resolve, reject) => {
      let img = document.createElement('img');
      img.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        let context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // Updated base64 image data at the target size
      };
      img.onerror = (error) => reject(error);
      img.src = base64;
    });
  }



  const setProcessedImage = (base64, resize = true) => {
    setShouldResize(resize);
    setImageData(base64);
  };


  const finishJobProcessing = () => {
    setIsProcessing(false);
    // Any other cleanup logic you might have
  };

  const handleGenerateButton = async () => {

    if (isProcessing) {
      console.warn('A job is already in progress. Please wait until it completes.');
      return;
    }
    
    if (!imageData) {
      console.warn('No image data available to send.');
      return;
    }
    setIsProcessing(true); // Prevent new jobs from starting until this one completes

    const maskBase64 = drawingBoardRef.current.getCanvasBase64();
    const { width = 864, height = 512 } = outputDimensions || {};
    
    let localPrompt = prompt;
    let localNegativePrompt = negativePrompt;
    
    const openAiRequest = async (textInput, threadID = null, localPrompt) => {

      const endpoint = '/.netlify/functions/submit-message';
      

      try {
          const response = await fetch(endpoint, {

              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ textInput, threadID, localPrompt }),
          });
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          // Assuming data includes threadID and runID, return them.
          return {
              threadID: data.threadID,
              runID: data.runID
          };

      } catch (error) {
          console.error("Error sending request to server:", error);
          setJobProgress("Error");
          return null;
      }
  };

  try {
      if (textInput) {
          setJobProgress('Thinking...');

          const getAssistantResponse = async (threadID) => {

              try {
                  const response = await fetch('/.netlify/functions/get-assistant-response', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ threadID }),
                  });

                  if (!response.ok) {
                      throw new Error('Network response was not ok');
                  }

                  const data = await response.json();

                  if (data.response) {
                      setAssistantResponse(data.response);
                  } else {
                      console.error('No response received from server.');
                  }
              } catch (error) {
                  console.error('Error fetching assistant response:', error);
              }
          };

          const executeFollowUpActionsWithIds = async (threadID, runID) => {
              await checkRunStatusAndHandle(threadID, runID);
          };

          const executeAndProcessAiRequest = async (textInput, localPrompt) => {

              const result = await openAiRequest(textInput, threadID, localPrompt);

              if (result) {
                  if (result.threadID !== threadID) {
                      setThreadID(result.threadID);
                  }
                  setRunID(result.runID); // Assuming this sets some global or context state
                  await executeFollowUpActionsWithIds(result.threadID, result.runID);
              } else {
                  console.error("Failed to obtain threadID and runID.");
              }
          };

          const fetchRunStatus = async (threadID, runID) => {
              const response = await fetch('/.netlify/functions/check-message-run-status', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ threadID, runID })
              });
              return await response.json();
          };

          const checkRunStatusAndHandle = async (threadID, runID) => {

              let runStatusCheck = await fetchRunStatus(threadID, runID); // Initial status check

              while (runStatusCheck.status === 'in_progress' || runStatusCheck.status === 'requires_action') {
                  if (runStatusCheck.status === 'requires_action') {

                      const requiredAction = runStatusCheck.required_actions;
                      const toolCalls = requiredAction.submit_tool_outputs.tool_calls;
                      const toolOutputs = []; // Array to collect all tool call outputs

                      for (const toolCall of toolCalls) {
                          const argumentsObject = JSON.parse(toolCall.function.arguments);
                          argumentsObject.tool_call_id = toolCall.id; // Adding the tool call ID to arguments object for later use



                          try {
                              if (toolCall.function.name === "set_image_prompt") {
                                  const output = await handleSetImagePrompt(argumentsObject);
                                  toolOutputs.push(output);
                              } else if (toolCall.function.name === "set_negative_image_prompt") {
                                  const output = await handleSetNegativeImagePrompt(argumentsObject);
                                  toolOutputs.push(output);
                              }
                          } catch (error) {
                              finishJobProcessing();
                              console.error(`Error occurred while handling tool calls: ${error}`);
                          }
                      }

                      if (toolOutputs.length > 0) {
                          await submitToolOutputsAndHandle(threadID, runID, toolOutputs);
                      }
                      console.log('Required actions have been handled.');
                      await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 3 seconds as a simple throttling mechanism
                      runStatusCheck = await fetchRunStatus(threadID, runID);
                  } else {
                      console.log('Run is in progress, waiting...');
                      await new Promise(resolve => setTimeout(resolve, 1500)); // Wait before re-checking
                      runStatusCheck = await fetchRunStatus(threadID, runID);
                  }
              }

              if (runStatusCheck.status === 'completed') {
                  console.log('Run has completed, retrieving messages.');
                  getAssistantResponse(threadID);
              } else {
                  finishJobProcessing();
                  console.log('Run did not complete successfully.');
              }
          };

          const submitToolOutputsAndHandle = async (threadID, runID, toolOutputs) => {

              // Submit tool outputs through your Netlify Function
              const response = await fetch('/.netlify/functions/submit-tool-outputs', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ threadID, runID, toolOutputs })
              });

              const data = await response.json();
              console.log('Tool outputs submitted:', data.message);
          };

          const handleSetImagePrompt = async (argumentsObject) => {
              const newPrompt = argumentsObject.new_prompt;
              localPrompt = newPrompt;
              setPrompt(newPrompt); // Assuming setPrompt is defined somewhere to handle the prompt update
              return { tool_call_id: argumentsObject.tool_call_id, output: 'success' };
          };

          const handleSetNegativeImagePrompt = async (argumentsObject) => {
              const newNegativePrompt = argumentsObject.new_negative_prompt;
              localNegativePrompt = newNegativePrompt;
              setNegativePrompt(newNegativePrompt); // Assuming setNegativePrompt is defined to handle the negative prompt update
              return { tool_call_id: argumentsObject.tool_call_id, output: 'success' };
          };

          await executeAndProcessAiRequest(textInput, localPrompt);

          setTextInput('');
      }

      let newBase64Data;
      let resizedWidth;
      let resizedHeight;
      let maskData; 

      const isCanvasEmpty = drawingBoardRef.current.isCanvasEmpty();

      saveToHistory(imageData, 'imageChange');
      
      if (activeSizeButton.size === 1.4 && !isCanvasEmpty) {
        try {
          resizedWidth = Math.round(width * activeSizeButton.size);
          resizedHeight = Math.round(height * activeSizeButton.size);
          
          // Resize main image
          let resizedBase64Data = await resizeBase64Img(imageData, resizedWidth, resizedHeight);
          newBase64Data = resizedBase64Data.split('base64,')[1];
          
          // If there's a mask to resize
          if (maskBase64) {
            const maskImageData = maskBase64.split('base64,')[1];
            let resizedMaskBase64Data = await resizeBase64Img(`data:image/png;base64,${maskImageData}`, resizedWidth, resizedHeight);
            maskData = resizedMaskBase64Data.split('base64,')[1];
          }
        } catch (error) {
          console.error('Error resizing images:', error);
        }
      } else {
        try {
          resizedWidth = Math.round(width * activeSizeButton.size);
          resizedHeight = Math.round(height * activeSizeButton.size);

          const resizedImageData = await resizeBase64Img(imageData, width, height);
          newBase64Data = resizedImageData.split('base64,')[1];

          if (!isCanvasEmpty) {
          maskData = maskBase64.split('base64,')[1];
          }
        } catch (error) {
          console.error('Error resizing image:', error);
        }
      }
      
      // Define or update inputData outside the if-else blocks to ensure any updates within those blocks are included.
      let inputData = {
        type: 'gen',
        width: resizedWidth,
        height: resizedHeight,
        denoisingStrength: denoisingStrength,
        prompt: localPrompt,
        negativePrompt: localNegativePrompt,
        init_images: [newBase64Data],
        controlnetStrength: controlnetStrength,
        activeDefButtonRes: activeDefButton.res,
        modelVersion: modelVersion,
        shapeDefinition: activeDefButton.name,
      };
      
      // Conditionally add the mask to inputData if it exists.
      if (maskData) {
        inputData.mask = maskData;
      }

      if(inspirationData) {
        const resizedinspirationData = await resizeBase64Img(inspirationData, width, height);

        inputData.inspiration = resizedinspirationData.split('base64,')[1];
        inputData.inspirationStrength = inspirationStrength;
      }

      const activeLoras = loras.filter(lora => loraInfluenceValues[lora.id] !== undefined);
      if (activeLoras.length > 0) {
        inputData.loras = activeLoras.map(lora => ({
          dataset_id: lora.id,
          influence: loraInfluenceValues[lora.id],
          project_name: lora.name,
          training_mode: lora.training_mode
        }));
      }

      const pollJobStatus = (jobId) => {
        let isPolling = false;
        let statusInterval = setInterval(async () => {

        let retryCount = 0;
        const maxRetries = 3;  // Set the maximum number of retries

            if (isPolling) return; // Avoid starting a new check if the last one hasn't finished
            isPolling = true;

            const netlifyStatusFunctionURL = '/.netlify/functions/check-generation-status';
            try {
                const statusResponse = await fetch(netlifyStatusFunctionURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ jobId }),
                });
    
                if (!statusResponse.ok) {
                    finishJobProcessing();
                    throw new Error(`Server responded with ${statusResponse.status}: ${statusResponse.statusText}`);
                }
    
                const { status, output } = await statusResponse.json();
    
                // Here you will handle the status accordingly
                switch (status) {
                    case "COMPLETED":
                        clearInterval(statusInterval);
                        const imageBase64 = output.images[0]; // Assuming the API response structure
                        setJobProgress("Finished!");
                        setImageDataWithHistory(`data:image/webp;base64,${imageBase64}`);
                        finishJobProcessing();
                        break;
                    case "FAILED":
                        clearInterval(statusInterval);
                        setJobProgress("Something went wrong, try again");
                        console.error("Job failed:", output);
                        finishJobProcessing();
                        break;
                    case "IN_QUEUE":
                        setJobProgress("Processing...");
                        break;
                    case "IN_PROGRESS":
                        setJobProgress("Generating...");
                        break;
                    default:
                        console.warn(`Encountered unexpected status: ${status}`);
                        setJobProgress(`Waiting... (${status})`);
                        break;
                }
            } catch (error) {
              if (retryCount < maxRetries) {
                  console.error("Error polling job status, attempting to retry...", error);
                  retryCount++; // Increment the retry counter
                  // Optionally, you might want to introduce a delay here (e.g., using setTimeout) before the next attempt
              } else {
                  clearInterval(statusInterval);
                  console.error("Error polling job status after multiple attempts:", error);
                  setJobProgress("Something went wrong, please try again.");
                  finishJobProcessing();
              }
            } finally {
                isPolling = false; // Allow new requests
              }
        }, 3000); // Poll every 3 seconds
      };

      async function submitAndPollJob() {
    
        handleClearCanvas();
        setJobProgress("Submitting...");
    
        try {
          const authToken = await currentUser.getIdToken(); // Get the authentication token
          const netlifyFunctionURL = '/.netlify/functions/handle-generation';
          // Only sending necessary data excluding any API keys
          const submitResponse = await fetch(netlifyFunctionURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}` // Include the token in the headers
            },
            body: JSON.stringify(inputData), 
          });
          if (!submitResponse.ok) {
            setJobProgress('Submitting job failed');
            throw new Error(`Server responded with ${submitResponse.status}: ${submitResponse.statusText}`);
          }

          const responseData = await submitResponse.json();

          if(responseData.jobId) {
            const jobId = responseData.jobId;

            pollJobStatus(jobId);

          } else {
              console.error("Received response, but it does not contain the expected image data.");
          }


        } catch (error) {
          console.error("An error occurred while submitting the job:", error);
          finishJobProcessing();
          setJobProgress("Failed to submit job");
        }
      }
      
    submitAndPollJob();

    } catch (error) {
      console.error(error);
    } 
  };

  const handleRefineButton = async () => {

    if (isProcessing) {
      console.warn('A job is already in progress. Please wait until it completes.');
      return;
    }
  
    if (!imageData) {
      console.warn('No image data available to send.');
      return;
    }
    setIsProcessing(true); // Block new jobs until this one completes
    handleClearCanvas();
    const { width = 864, height = 512 } = outputDimensions || {};


  
    try {
      const resizedBase64Image = await resizeBase64Img(imageData, width * 2, height * 2);
      const base64Data = resizedBase64Image.split('base64,')[1];
  
      // Define job input for the refine phase
      let inputRefineData = {
        type: "refine",
        width: Math.round(width * 2),
        height: Math.round(height * 2),
        init_images: [base64Data],
        modelVersion: modelVersion
      };
  
      // Start the refine phase
      setJobProgress("Submitting...");
      const refineOutputBase64 = await submitAndPollJob(inputRefineData, "refine");
      
      if (!refineOutputBase64) {
        console.error("Refinement failed. Could not proceed to upscale phase.");
        return;
      }
  
      // Define job input for the upscale phase, using the output of the refine phase
      let inputUpscaleData = {
        type: "upscale",
        image: refineOutputBase64,
      };
  
      // Start the upscale phase
      setJobProgress("Upscaling...");
      const upscaleOutputBase64 = await submitAndPollJob(inputUpscaleData, "upscale");
      
      // Handle the final output from the upscale job
      if (upscaleOutputBase64) {
        setJobProgress("Finished!");
        setImageDataWithHistory(`data:image/webp;base64,${upscaleOutputBase64}`);
      } else {
        console.error("Upscaling failed. Process ended.");
      }
  
    } catch (error) {
      console.error("An error occurred during the image processing phases:", error);
    } finally {
      finishJobProcessing(); // Ensure isProcessing is reset and UI is updated accordingly
    }
  };
  
  // Supports both 'refine' and 'upscale' jobs
  const submitAndPollJob = async (inputData, phase) => {
    const netlifyFunctionURL = '/.netlify/functions/handle-generation';
  
    try {
      const authToken = await currentUser.getIdToken(true); // Get the authentication token, force refresh if needed
      const submitResponse = await fetch(netlifyFunctionURL, {
        method: 'POST',
        headers: {'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}` // Include the token in the headers
        },
        body: JSON.stringify(inputData),
      });
      
      if (!submitResponse.ok) {
        setJobProgress('Submitting job failed');
        throw new Error(`Server responded with ${submitResponse.status}: ${submitResponse.statusText}`);
      }
      
      const { jobId } = await submitResponse.json();
      if (!jobId) throw new Error("No jobId received from submission.");
  
      // Poll status until completion or failure
      return await pollJobStatus(jobId, phase);
  
    } catch (error) {
      console.error(`An error occurred while submitting the ${phase} job:`, error);
      return null;
    }
  };
  
  // Poll job status, parameterised to work with both 'refine' and 'upscale'
  const pollJobStatus = (jobId, phase) => new Promise((resolve, reject) => {
    let isPolling = false;
    const statusInterval = setInterval(async () => {
      if (isPolling) return;
      isPolling = true;
  
      const netlifyStatusFunctionURL = '/.netlify/functions/check-generation-status';
      try {
        const statusResponse = await fetch(netlifyStatusFunctionURL, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ jobId }),
        });
  
        if (!statusResponse.ok) {
          throw new Error(`Server responded with ${statusResponse.status}: ${statusResponse.statusText}`);
        }
  
        const { status, output } = await statusResponse.json();
        switch (status) {
          case "COMPLETED":
            clearInterval(statusInterval);
            if (phase === "refine") {
                resolve(output.images[0]);

            } else if (phase === "upscale") {
                resolve(output.image); 

            } else {
                // Default action if phase does not match the above categories
                console.log("Phase completed successfully, but phase was not specifically handled:", phase);
                resolve(output.images[0]); // Adjust based on needs
            }
            break;
          case "FAILED":
            clearInterval(statusInterval);
            console.error(`${phase} job failed:`, output);
            setJobProgress("Something went wrong, try again");
            resolve(null); // Fail without throwing to allow the calling function to handle cleanup
            break;
          case "IN_QUEUE":
            setJobProgress(phase === "refine" ? "Processing..." : "Upscaling...");
            break;
          case "IN_PROGRESS":
            setJobProgress(phase === "refine" ? "Refining..." : "Upscaling...");
            break;
          default:
            console.warn(`Encountered unexpected status: ${status}`);
            setJobProgress(`Waiting... (${status})`);
            break;
        }
      } catch (error) {
        clearInterval(statusInterval);
        console.error("Error polling job status:", error);
        reject(error); // Signal an unexpected failure
      } finally {
        isPolling = false;
      }
    }, 3000); // Poll every 3 seconds
  });

  const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      // User is now signed out
      localStorage.removeItem('authToken');
      navigate('/login'); // Navigate to login after logout
    }).catch((error) => {
      console.error("Logout failed: ", error);
    });
  };

  const downloadBlenderPlugin = async (type) => {
    const storage = getStorage();
    let filePath = '';

    if (type === 'extension') {
        filePath = '/public/hosted_files/optic_extension_092.zip';
    } else if (type === 'addon') {
        filePath = '/public/hosted_files/optic_addon_092.zip';
    }

    const fileRef = ref(storage, filePath);
  
    console.log(fileRef);

    try {
      const url = await getDownloadURL(fileRef);
      const link = document.createElement('a');
      link.href = url;
      link.download = filePath.split('/').pop(); // Extracts the file name from the path
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the Blender plugin:', error);
    }
  };
  
  const historyContainerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      if (Math.abs(e.deltaY) > 0 && historyContainerRef.current) {
        historyContainerRef.current.scrollLeft += e.deltaY;
      }
    };

    const currentRef = historyContainerRef.current;
    if (menuOpen && currentRef) {
      currentRef.addEventListener('wheel', handleWheel, {passive: false});
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleWheel);
      }
    };
  }, [menuOpen]); // Re-run this effect when menuOpen changes

  
  const promptsContainerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      if (promptsContainerRef.current) {
        e.preventDefault();
        promptsContainerRef.current.scrollLeft += e.deltaY;
        console.log('Scrolling Detected (Horizontal):', e.deltaY);
      }
    };

    const currentRef = promptsContainerRef.current;
    if (!isChatVisible && currentRef) {
      currentRef.addEventListener('wheel', handleWheel, { passive: false });
      console.log('Event listener added');
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleWheel);
        console.log('Event listener removed');
      }
    };
  }, [isChatVisible]);

  
  return (
    <div className="App">

  <>
    <Helmet>
      <title>Optic Early Access</title>
      <meta name="description" content="Optic · Unleash your creativity with Optic AI tool. Built by designers, for designers." />
    </Helmet>
  </>

      <div className="sidebarContainer">

        <div className="settingsContainer">

          <div className="mainSettingsContainer">

          <div className="mainSettingsTitle">
            <span
              style={{ fontSize: '16px', color: activeSection === 'settings' ? 'white' : '#575757', cursor: 'pointer', userSelect: 'none'  }}
              className='boldText'
              onClick={() => handleTitleClick('settings')}
            >
              Settings
            </span>
            <span
              style={{ fontSize: '16px', color: activeSection === 'training' ? 'white' : '#575757', cursor: 'pointer', userSelect: 'none' }}
              className='boldText'
              onClick={() => handleTitleClick('training')}
            >
              Lenses
            </span>
            <span
              style={{ fontSize: '16px', color: activeSection === 'presets' ? 'white' : '#575757', cursor: 'pointer', userSelect: 'none' }}
              className='boldText'
              onClick={() => handleTitleClick('presets')}
            >
              Presets
            </span>

          </div>

          <div style={{ display: 'inline-block', marginTop: '13px' }} className="horizontal-line"></div>

          {activeSection === 'settings' && (
          <div className="settingsModuleContainer">

            <div style={{ display: 'flex', justifyContent: 'space-between'}}>

              <span style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', marginTop: '10px' }} className='boldText'>General</span>

              <div className="settingsIcon" style={{ marginRight: '5px', marginTop: '9px' }}
              onClick={openPreferences}></div>
              {showPreferences && <Preferences 
                contextAwareGeneration={contextAwareGeneration}
                setContextAwareGeneration={setContextAwareGeneration}
                closePreferences={() => {closePreferences()}}
                modelVersion={modelVersion}
                setModelVersion={setModelVersion}
                />}
            </div>

            {/* <div className="toggleSettingContainer">

              <Tooltip message="The output resolution of the model. Normal is faster so it's better for general use. 2X is slower but produces higher quality results." 
                position="right"
              style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginRight: '87px', color: '#808080' }} 
              className='normalText'>Output Size</Tooltip>

              <div className="toggle-container">
                {modelSize.map((button) => (
                <button
                key={button.id}
                className={`toggle-btn${isSizeActive(button) ? ' active' : ''}`}
                style={{ fontSize: '10px'}}
                onClick={() => toggleSizeActive(button.id)}
                >
                {button.name}
                </button>
                ))}
              </div>

            </div> */}

            <div style={{ marginTop:'18px'}}>

            <Tooltip message="Dictates how much influence the model has on the final result. Higher values will result in larger changes to the original image." 
                position="right" 
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginRight: '27px', color: '#808080' }} 
                className='normalText'>Model Influence</Tooltip>

              <input
                type="range"
                min="0"
                max="1"
                step="0.05"
                value={denoisingStrength}
                onChange={e => setDenoisingStrength(e.target.valueAsNumber)} // Update state on slider move
            />

            <span 
              style={{ fontSize: '12px', marginLeft: '20px', color: '#888888' }} 
              className='normalText'>
              {denoisingStrength === 1 ? 'Full' : denoisingStrength === 0 ? 'Off' : denoisingStrength.toFixed(2)}
            </span>


            </div>

            <span style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', marginTop: '25px' }} className='boldText'>Shape</span>

            <div>

              <Tooltip message="Shape control allows for independent control of the shapes in the input image. The higher the value the stricter the output will adhere to the shapes in the image." 
                position="right"
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '25px', marginRight: '35px', color: '#808080' }} 
                className='normalText'>Shape Control</Tooltip>

              <input
              type="range"
              min="0.0"
              max="1.0"
              step="0.05"
              value={controlnetStrength}
              onChange={e => setControlnetStrength(e.target.valueAsNumber)} // Update state on slider move
              />

              <span style={{ fontSize: '12px', marginLeft: '20px', color: '#888888' }} className='normalText'>{controlnetStrength.toFixed(2)}</span>

            </div>

            <div className="toggleSettingContainer" style={{ marginTop:'5px'}}>

            <Tooltip message="Shape definition allows you to specify the detection method for the input image shapes. Smart is recommended for most cases. Literal is more strict and will only consider the exact shapes in the image preserving details the smart mode might generilize. It works better for interior line work for example." 
                position="right" 
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginRight: '55px', color: '#808080' }} 
                className='normalText'>Shape Definition</Tooltip>

              <div className="toggle-container">
                {toggleDefData.map((button) => (
                <button
                key={button.id}
                className={`toggle-btn${isDefActive(button) ? ' active' : ''}`}
                style={{ fontSize: '10px'}}
                onClick={() => toggleDefActive(button.id)}
                >
                {button.name}
                </button>
                ))}
              </div>

            </div>

            <span style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', marginTop: '5px' }} className='boldText'>Inspiration</span>

            <div>

              <Tooltip message="Inspiration image allows you to inspire the output with another image. Both conceptually and feature-wise." 
                position="right" 
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '20px', marginRight: '35px', color: '#808080'}} 
                className='normalText'>Insp. Influence</Tooltip>

              <input
                type="range"
                min="0.0"
                max="1.0"
                step="0.05"
                value={inspirationStrength}
                onChange={e => setInspirationStrength(e.target.valueAsNumber)} // Update state on slider move
              />

              <span style={{ fontSize: '12px', marginLeft: '20px', color: '#888888' }} className='normalText'>{inspirationStrength.toFixed(2)}</span>

              <button
                onClick={inspirationData ? handleRemoveInspiration : handleInspirationClick}
                className={`genericButton ${inspirationData ? 'inspirationButton' : ''}`}
                style={{
                  width: '250px',
                  height: '40px',
                  marginTop: '28px',
                  marginLeft: '32px',
                  backgroundImage: inspirationData ? `url(${inspirationData})` : 'none',
                }}
              >
                <input
                  ref={inspirationInputRef}
                  type="file"
                  accept="image/jpeg,image/png,image/webp"
                  onChange={handleInspirationChange}
                  style={{ display: 'none' }} // Hide the native file input
                />

                {inspirationData ? (
                  <span className="removeText">Remove</span>
                ) : (
                  <span className="generateText">Add Image</span>
                )}
              </button>

            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginRight: '20px'}}>

              <span style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', marginTop: '23px' }} className='boldText'>Generative Brush</span>

              <div className="toggle-container">
                {genBrushTool.map((button) => (
                  <button
                    key={button.id}
                    className={`toggle-btn${isGenBrushToolActive(button) ? ' active' : ''}`}
                    style={{ fontSize: '10px' }}
                    onClick={() => handleGenBrushClick(button)} // Call the new handler
                  >
                    {button.name}
                  </button>
                ))}
              </div>

            </div>
            {useGenBrushTool.state && (
            <div>

              <Tooltip message="Generative Brush allows you to specify by inpainting which areas of the image the model should generate. Works simply by clicking and dragging on the image." 
                position="right" 
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '20px', marginRight: '55px', color: '#808080'}} 
                className='normalText'>Brush Size</Tooltip>

              <input
                type="range"
                min="1"
                max="100"
                step="1"
                value={brushSize}
                onChange={e => setBrushSize(e.target.valueAsNumber)} // Update state on slider move
              />

              <span style={{ fontSize: '12px', marginLeft: '20px', color: '#888888' }} className='normalText'>{brushSize.toFixed(0)}</span>

              <button onClick={handleClearCanvasButton} className="genericButton" style={{width: '150px', height: '40px', marginTop: '28px', marginLeft: '82px'}}>

                <span  className= "generateText">Clear</span>

              </button>

            </div>
            )}

            <span style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', marginTop: '5px' }} className='boldText'>Blender Plugin</span>

            <div>

              <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>

              <button
                  onClick={() => downloadBlenderPlugin('addon')}
                  className= "genericButton"
                  style={{
                    width: '250px',
                    height: '40px',
                    marginTop: '28px',
                    marginLeft: '20px',
                  }}
                ><span className="boldText" style={{ fontSize: '12px'}}>Addon (4.1-) </span>
                </button>

                <button
                  onClick={() => downloadBlenderPlugin('extension')}
                  className= "genericButton"
                  style={{
                    width: '250px',
                    height: '40px',
                    marginTop: '28px',
                    marginRight: '20px',
                  }}
                ><span className="boldText" style={{ fontSize: '12px'}}>Extension (4.2+) </span>
                </button>

              </div>

              <span 
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '10px', marginRight: '35px', color: '#808080'}} 
                className='normalText'>Try Optic directly in Blender with our new plugin.</span>
              <span 
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '10px', marginRight: '35px', color: '#808080'}} 
                className='normalText'>To Install:</span>
              <span 
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '10px', marginRight: '35px', color: '#808080'}} 
                className='normalText'>1. Download the zip for your version of Blender.</span>
              <span 
                style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '10px', marginRight: '35px', color: '#808080'}} 
                className='normalText'>2. Directly install the addon or extension in Blender preferences without unzipping the folder.</span>

            </div>

            <div style={{display: 'flex', justifyContent: 'center', paddingBottom:'10px'}}>

              <span style={{ display: 'inline-block', fontSize: '12px' , userSelect: 'none', color:'#222525'}} className='boldText'>Lex Fridman is smart and handsome.</span>

            </div>

            <div style={{display: 'flex', justifyContent: 'center', paddingBottom:'10px'}}>

              <span style={{ display: 'inline-block', fontSize: '12px' , userSelect: 'none', color:'#222525'}} className='boldText'>Lex Fridman is smart and handsome.</span>

            </div>

            </div>) }  {/*End of settings section*/}

            {activeSection === 'training' && (
            <div className="settingsModuleContainer">

            <span style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', marginTop: '10px' }} className='boldText'>Train</span>

            <div style={{ marginTop:'-5px'}}>

                <button
                  onClick={openTrainer}
                  className={"genericButton"}
                  style={{
                    width: '250px',
                    height: '40px',
                    marginTop: '28px',
                    marginLeft: '32px'
                  }}
                  disabled={loadingLoras}
                >
                    <span className="generateText">{loadingLoras
                    ? 'Loading...' 
                    : loras.length >= 3 
                      ? 'Open Lenses (3/3)' 
                      : 'Open Lenses'
                    }</span>
                </button>
                <Trainer isOpen={trainerOpen} closeTrainer={closeTrainer} onTrainingCompleted={loadUserLoras} loras={loras}/>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom:'10px'}}>

                <span style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', marginTop: '30px' }} className='boldText'>Your Lenses</span>

                <span style={{ display: 'inline-block', fontSize: '12px', marginRight: '10px', marginTop: '30px', color: '#888888' }} className='normalText'>{loras.length}/3</span>

              </div>

              {Array(3).fill(null).map((_, index) => {
                const lora = loras[index];
                return (
                  <div className='trainingContainer' key={index}>
                    <div className='trainingThumbnail' onMouseLeave={() => handleMouseLeave(lora?.id)} onClick={() => lora && handleDeleteClick(lora.id, lora.id)}>
                      {lora ? (
                        lora.thumbnailUrl ? (
                          <img src={lora.thumbnailUrl} alt={lora.name} />
                        ) : (
                          <div></div>
                        )
                      ) : (
                        <div className='defaultThumbnail'></div>
                      )}
                      {lora && (
                        <div className="trainingThumbnailOverlay">
                          {deletingLora ? 'Deleting...' : (confirmLoraDeleteState[lora.id] ? 'Are you sure?' : 'Delete')}
                        </div>
                      )}
                    </div>
                    <div style={{ marginLeft: '20px', marginTop: '13px' }}>
                      <div className='trainingNameContainer'>
                        <span style={{
                          display: 'inline-block',
                          fontSize: '12px',
                          marginLeft: '3px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '155px',
                          opacity: lora ? 1 : 0.4
                        }} className='boldText'>{lora ? lora.name : 'Empty Slot'}</span>
                        {lora && (
                          <span 
                            className="deleteLoraIcon" 
                            onClick={() => deleteLora(lora.id, lora.id)}
                            disabled={deletingLora}
                            style={{cursor: 'pointer', marginTop:'-5px'}}
                          ></span>
                        )}
                      </div>
                      <div style={{ marginTop: '0px' }}>
                        <input
                          type="range"
                          min="0"
                          max="1"
                          step="0.05"
                          value={lora ? (loraInfluenceValues[lora.id]?.toFixed(2) || 0.0) : (loraInfluenceValues['default'] || 0.0)}
                          onChange={(e) => handleInfluenceChange(lora ? lora.id : 'default', parseFloat(e.target.value))}
                        />
                        <span style={{ fontSize: '12px', marginLeft: '10px', color: '#888888' }} className='normalText'>
                          {lora ? (loraInfluenceValues[lora.id] === 0 ? 'OFF' : (loraInfluenceValues[lora.id]?.toFixed(2) || 'OFF')) : (loraInfluenceValues['default'] || 0.0).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div style={{display: 'flex', justifyContent: 'center', paddingBottom:'10px'}}>

                <span style={{ display: 'inline-block', fontSize: '12px' , userSelect: 'none', color:'#222525'}} className='boldText'>Lex Fridman is smart and handsome.</span>

              </div>
            </div>)} {/* End of training section */}

                {activeSection === 'presets' && (
            <div className="settingsModuleContainer">

            <span style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', marginTop: '10px' }} className='boldText'>Presets</span>

            <div style={{ marginTop:'-5px'}}>

              <DropdownMenu 
                presets={presets}
                selectedItem={selectedPreset}
                onPresetSelect={handlePresetSelect}
                onNameChange={handlePresetNameChange}
                onSavePreset={savePreset}
                currentUser={currentUser}
                inspirationData={inspirationData}
                onDeletePreset={deletePreset}
                onAddPreset={addPreset}
                buttonStyle={{
                  width: '250px',
                  height: '40px',
                  marginTop: '28px',
                  marginLeft: '32px',
                  fontSize: '12px',
                }}>
              </DropdownMenu>

            </div>

            </div>)} {/* End of presets section */}

          </div>

          <div>

          <div className="mainSettingsContainer">

            <span style={{  fontSize: '16px', marginTop: '20px', marginLeft: '10px' }} className='boldText'>Assistant</span>

            <div style={{ display: 'inline-block', marginTop: '13px' }} className="horizontal-line"></div>

            <div className="settingsModuleContainer2" style={{ marginTop:'0px'}}>

              <div className="toolTipContainer" style={{  marginLeft: '20px', marginTop: '10px', marginRight: '29px' }}>

                <span style={{ fontSize: '12px', opacity: '30%', marginRight: '6px' }}>Job Status: </span>
                <span style={{ fontSize: '12px' }}>{jobProgress}</span>

              </div>

              <div className="assistantResponseContainer">

                <span style={{  fontSize: '12px', opacity: '30%', marginLeft: '12px', marginTop: '10px' }}>Response: </span>
                <span style={{  fontSize: '12px', marginLeft: '12px', marginTop: '10px', marginRight: '29px' }}>{assistantResponse}</span>

              </div>

            </div>

          </div>

          </div>

        </div>


        <div className="linksContainer">

          <div className="logo" onClick={() => openLinkInNewTab('https://www.udinbv.com/')}>

          </div>

          <span className= 'socialLink' style={{ fontSize: '12px' }} onClick={() => manageSubscription(customerId)}>Subscription</span>

          <span className= 'socialLink' style={{ fontSize: '12px' }} onClick={() => openLinkInNewTab('https://discord.gg/p42eg8FFe6')}>Discord</span>

          <span className= 'socialLink' style={{ fontSize: '12px' }} onClick={handleLogout}>Log Out</span>

        </div>

      </div>

      <div className="mainContainer">

        <div
          className={`pictureContainer ${isDragActive ? 'drag-active' : ''}`}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          // Remove the backgroundImage style
          style={{ position: 'relative' }}
        >
          {imageData && (
            <img
              src={imageData}
              alt="Background"
              className="backgroundImage"
              draggable="false"
            />
          )}

          {imageData && outputDimensions.width && outputDimensions.height && (
            <DrawingBoard
              width={outputDimensions.width}
              height={outputDimensions.height}
              ref={drawingBoardRef}
              brushSize={brushSize}
              saveToHistory={saveToHistory}
              useGenBrushTool={useGenBrushTool}
            />
          )}
        {jobProgress !== "Finished!" && (
          <div className="loadingScreen">

          <span style={{ fontSize: '54px' }}>{jobProgress}</span>
          
          </div>
        )}


          <div className="pictureToolsContainer">

            {imageData && (
              <button onClick={handleUndoButton} disabled={!history} className="genericButton" style={{width: '55px', height: '52px'}}>

              <div className="revertIcon" style={{ marginLeft: '2px' }}></div>

              </button>
            )}
            {imageData && (
              <button onClick={downloadImage} className="genericButton" disabled={!imageData} style={{width: '56px', height: '52px'}}>

              <div className="downloadIcon" style={{ marginLeft: '3px' }}></div>

              </button>
            )}
            {imageData && (
              <div onClick={handleRemoveImageClick} className="genericButton" style={{ backgroundColor: confirmRemove ? 'rgb(232, 93, 68)' : '' }}>
              {confirmRemove ? (
                <div className="boldText" style={{ marginLeft: '3px', marginTop: '8px', opacity: '100%', fontSize: '12px' }}>
                  {"sure?"}
                </div>
              ) : (
                <div className="closeIcon" style={{ marginLeft: '2px' }}></div>
              )}
              </div>
            )}

          </div>

          <div className='pictureCenterContainer'>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/jpeg,image/png,image/webp"
              onChange={handleFileChange}
              style={{ display: 'none' }} // Hide the native file input
            />
             {!imageData && (
                <button onClick={handleButtonClick} className={`uploadButton ${isDragActive ? 'drag-active' : ''}`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}>

                  <div className= "uploadIcon" style={{marginTop: '12px'}}>

                  </div>

                  <span className="normalText" style={{fontSize: '12px', marginTop: '-40px', opacity: '35%'}}>Upload Image</span>

                </button>
            )}

          </div>

          <div className="pictureToolsContainer2" style={{height: '120px'}}>

            {!isExpanded && imageData && (
              <Tooltip message="History" position="top" delay={1000} distance={-50}>
                <button onClick={handleExpand} className="genericButton" style={{width: '56px', height: '52px', marginTop: '59px'}}>

                  <div className="historyIcon" style={{ marginLeft: '3px' }}></div>

                </button>
              </Tooltip>
            )}

            {isExpanded && imageData && (
              <div className='historyContainer' style={{ marginTop: '32px'}}>
                <div className="historyIcon" onMouseEnter={toggleHoverState} onMouseLeave={toggleHoverState} onClick={handleExpand} 
                    style={{ marginLeft: '13px', marginTop: '37.5px' }}>
                </div>
                <div className='historyContentContainer' key={menuOpen ? "open" : "closed"} ref={historyContainerRef}>
                  {
                    history
                      .filter(item => item.actionType === 'imageChange')
                      .reduce((unique, item) => {
                        return unique.find(uniqueItem => uniqueItem.data === item.data) ? unique : [...unique, item];
                      }, [])
                      .map((filteredItem, filteredIndex) => (
                        <div key={`thumbnail-${filteredIndex}`} 
                            style={{ margin: '5px' }} 
                            onClick={(e) => { 
                              if (isProcessing) {
                                e.preventDefault(); // Optionally prevent default if necessary for your use case
                                return;
                              }

                              e.preventDefault();
                              setImageData(filteredItem.data);
                              
                              // Find the actual index in the full history array, which remains unchanged
                              const actualIndex = history.findIndex(item => item.data === filteredItem.data && item.actionType === filteredItem.actionType);
                              
                              setPosition(actualIndex);
                            }}>
                          <img src={filteredItem.data} alt={`Thumbnail ${filteredIndex}`} width="100" height="auto"
                              style={{ maxWidth: '100px', maxHeight: '56px', objectFit: 'cover', cursor: 'pointer'}}
                              draggable="false"
                              className='historyThumbnail'/>
                        </div>
                      ))
                  }
                </div>
              </div>
            )}

            {imageData && (
              <Tooltip message="Refines the image adding details and upscaling it to close to 4K resolution. More power intensive" position="top" distance={-60}>
                <button onClick={handleRefineButton} className="genericButton" style={{ width: '150px', height: '40px', marginTop: '72px', marginLeft: '32px' }}>
                  <span className="generateText">Refine</span>
                </button>
              </Tooltip>
            )}

          </div>

        </div>

        <div className="bottomContainer" >

        {isChatVisible ? (
          <div className="textInputContainer">
            <input
              type="text"
              placeholder="Describe your intention (optional)"
              className="textInput"
              maxLength="512"
              value={textInput}
              onChange={handleTextInputChange}
            />
          </div>
        ) : (
          <div className="promptsWrapper">

          <div className="promptsContainer" ref={promptsContainerRef}>
            <button
              className="addButton"
              onClick={addPrompt}
              style={{width: '52px', height: '52px'}}
            >
            <div className="addPromptIcon" style={{ marginLeft: '0px' }}></div>
            </button>
            {promptList.map((item, index) => (
              <div
                key={index}
                className={`promptContainer ${fadeStates[index] ? 'fade-in' : ''}`} // Ensure fade-in class is applied correctly
                onDoubleClick={() => handleDoubleClick(index, item.term)}
                onMouseDown={(e) => handleMouseDown(index, e)}
                style={{ 
                  backgroundColor: interpolateColor(promptInfluences[item.term], bgColorStops),
                  color: interpolateColor(promptInfluences[item.term], textColorStops),
                  position: 'relative',
                }}
              >
                {editableIndex === index ? (
                  <input
                    type="text"
                    className="promptInput"
                    value={editablePrompt}
                    maxLength="100"
                    onChange={handlePromptChange}
                    onBlur={handlePromptBlur}
                    onKeyDown={handleKeyPress}
                    autoFocus
                  />
                ) : (
                  item.term
                )}
                {showDragValue && dragIndex === index && ( // Only show drag overlay if showDragValue is true
                <div className="dragOverlay">
                  {dragValue.toFixed(1)}
                </div>
                )}
              </div>
            ))}
          </div>
          <div className="leftGradient"></div>
          <div className="rightGradient"></div>
        </div>
        )}

          <Tooltip message="Text Input Mode" position="top" delay={1000} distance={0}>
            <div onClick={handleChatButtonClick} className="genericButton">
              <div className="textInputIcon" style={{ marginLeft: '3px' }}></div>
            </div>
          </Tooltip>
          <div onClick={!gettingLabels ? handleGenerateButton : null} className="generateButton">

          <span className="generateText">
            {gettingLabels ? 'Loading...' : 'Generate'}
          </span>

          </div>
        
        </div>

      </div>

    </div>
  );
}

export default Tool;




